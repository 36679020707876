import createCache from '@emotion/cache';
import {
  CacheProvider,
  ThemeProvider as StyledThemeProvider
} from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import BranchConnectionVerification from 'BranchConnectionVerification';
import { AryAlertTypes } from 'framework/base/components/AryAlert';
import {
  GET_DESCRIPTION_TEMPLATES,
  GET_SYSTEM_SETTINGS
} from 'framework/base/constants/aryGraphqlSchemas';
import arySystemSettings, {
  fontSizes
} from 'framework/base/constants/arySystemSettings';
import DescriptionTemplatesContext from 'framework/base/contexts/DescriptionTemplatesContext';
import LayoutTypeContext from 'framework/base/contexts/LayoutTypeContext';
import ShowCurrenciesContextProvider from 'framework/base/contexts/ShowCurrenciesContextProvider';
import SystemSettingsContext from 'framework/base/contexts/SystemSettingsContext';
import useAryAlert from 'framework/base/hooks/useAryAlert';
import { useAryLazyQuery } from 'framework/base/hooks/useAryGraphql';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import useTemplateParsing from 'framework/base/hooks/useTemplateParsing';
import THEMES from 'framework/base/theme/constants';
import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {
  setDoBootstrap,
  setErrors,
  setNoVersionError,
  updateUserCurrencies
} from 'redux/slices/systemSlice';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler,
  authService,
  useAuth
} from 'spec';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import './assets/fontYekanBakh.css';
import createTheme from './framework/base/theme/index';
import './language/translation';
import appRoutes, { settingsRoutes } from './routes';

const AryNoVersionModal = lazy(() =>
  import('framework/base/components/AryNoVersionModal')
);

const { ApplicationPaths } = ApiAuthorizationConstants;

const DEFAULT_LANGUAGE = 'fa';

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin]
});

const DirectionProvider = ({ rtl = false, children }) => {
  if (!rtl) {
    return children;
  }
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const contentTypes = {
  loading: 0,
  settings: 1,
  application: 2
};

const App = () => {
  const { t, i18n } = useTranslation();
  const reduxStore = useStore();
  const navigate = useNavigate();
  const langDirection = t('dir');
  const layoutType = useAryLayoutType();
  const [AryAlert, showAlert] = useAryAlert({
    onClose: () => {
      reduxStore.dispatch(setErrors([]));
    }
  });
  const auth = useAuth();
  const intervalId = useRef(null);
  const versionExpireTimeRef = useRef(null);
  document.body.dir = langDirection;

  const hasProfitAndLossStatementViewAccess = auth?.ProfitAndLossStatement;
  const hasSubmitOutgoingDraftAccess = auth?.OutgoingDraftRE;
  const hasSubmitIncomingDraftAccess = auth?.IncomingDraftRE;
  const hasSubmitCashTransactionAccess = auth?.CashTransactionRE;
  const hasSubmitTransferBetweenAccountsAccess =
    auth?.TransferBetweenAccountsRE;
  const hasSubmitBankTransactionAccess = auth?.BankTransactionRE;
  const hasViewDraftDocumentsListAccess = auth?.DraftDocumentsList;
  const hasViewBranchesListAccess = auth?.BranchesList;
  const hasViewBankAccountsListAccess = auth?.BankAccountsList;

  const [contentStatus, setContentStatus] = useState(contentTypes.loading);
  const [systemSettings, setSystemSettings] = useState({});
  const [noVersionModalOpen, setNoVersionModalOpen] = useState(false);

  const [
    getSystemSettings,
    { data: getSystemSettingsQueryData, loading: getSystemSettingsLoading }
  ] = useAryLazyQuery({
    gqlConfig: GET_SYSTEM_SETTINGS,
    items: `
      systemLanguage
      mainCurrency
      isGregorian
      defaultPhonePrefix
      profitAndLossAccuracy
      documents {
        documentTitle
        isManual
        issuingOrganization
      }
      userCurrencies {
        currencyCode
        decimalPlaces
        dollarRate
        isDivision
      }
      fileId
      exchangeName
      address
      slogan
      descriptions
      groupNames
      phoneNumbers {
        countryCode
        phoneNumber
        communicationWays
      }
      bankInfos {
        accountOwnerName
        bankAccountNumber
        cardNumber
        iBan
      }
      fontSize
      isDarkMode
      timeZone
      showCurrencyByTitle
      isDigitsLatin
      showReportOnWeb
      defaultSendWhatsApp
      defaultUpdateCreditLimit
    `
  });

  const [getDescriptionTemplates, { data: descriptionTemplates }] =
    useAryLazyQuery({
      gqlConfig: GET_DESCRIPTION_TEMPLATES,
      items: `
        id
        body
      `
    });

  const appTheme = createTheme(
    getSystemSettingsQueryData?.isDarkMode ? THEMES.DARK : THEMES.DESIGNER,
    getSystemSettingsQueryData,
    langDirection,
    layoutType
  );

  async function bootstrap() {
    const resp = await getSystemSettings();
    await getDescriptionTemplates(resp?.data?.systemLanguage || DEFAULT_LANGUAGE);
    await setFontSize(resp?.data);
    if (!intervalId.current) {
      authService.checkSession();
      intervalId.current = setInterval(() => {
        authService.checkSession();
      }, 30000);
    }
  }

  async function setFontSize(data) {
    const htmlTag = document.getElementsByTagName('html')[0];
    switch (data?.fontSize) {
      case 1: // small
        htmlTag.style.fontSize = fontSizeCalculation(0.8);
        break;
      case 2: // medium
        htmlTag.style.fontSize = fontSizeCalculation(1);
        break;
      case 3: // large
        htmlTag.style.fontSize = fontSizeCalculation(1.2);
        break;
      default:
        htmlTag.style.fontSize = '16px';
    }
  }

  const fontSizeCalculation = (coefficient) => {
    const minFontSize = 13;
    const maxFontSize = 36;

    // Define the base dimensions for the calculation
    const baseWidth = 1440; // base width for 16px font size
    const baseHeight = 1024; // base height for 16px font size
    const baseFontSize = 16; // base font size for the base dimensions

    // Get the current viewport dimensions
    const vw = window.innerWidth;
    const vh = window.innerHeight;

    // make width less important
    const ratio = layoutType === 'laptop' ? 0.9 : 0.7;

    // Use the geometric mean of the scale factors
    const scaleFactor =
      Math.sqrt((vw * ratio) ** 2 + vh ** 2) /
      Math.sqrt((baseWidth * ratio) ** 2 + baseHeight ** 2);

    // Calculate the new font size based on the scale factor and coefficient
    let newFontSize = baseFontSize * scaleFactor * coefficient;

    newFontSize = Math.max(minFontSize, Math.min(newFontSize, maxFontSize));

    // Round the final font size to top
    return `${Math.floor(newFontSize)}px`;
  };

  const parseDescription = useTemplateParsing(descriptionTemplates);

  function hasSystemSettings(settings) {
    if (!settings) return false;
    if (
      !settings.mainCurrency ||
      !settings.profitAndLossAccuracy ||
      !settings.userCurrencies?.length ||
      !settings.exchangeName ||
      !settings.groupNames?.length
    ) {
      return false;
    }
    return true;
  }

  function getBaseRoute() {
    switch (contentStatus) {
      case contentTypes.loading:
        return <Route path="/*" element={<AryLoadingAnimation />} />;
      case contentTypes.settings:
        return (
          <>
            <Route path="/*" element={<Navigate replace to="/welcome" />} />
            {lazyRoutes(settingsRoutes, layoutType)}
          </>
        );
      case contentTypes.application: {
        if (!descriptionTemplates?.length || !auth) {
          return <Route path="/*" element={<AryLoadingAnimation />} />;
        }
        return (
          <>
            <Route
              path="/system-settings"
              element={
                <Navigate
                  replace
                  to={
                    hasProfitAndLossStatementViewAccess
                      ? '/dashboard/profit-and-loss-statement'
                      : '/dashboard/customer/list'
                  }
                />
              }
            />
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={
                    hasProfitAndLossStatementViewAccess
                      ? '/dashboard/profit-and-loss-statement'
                      : '/dashboard/customer/list'
                  }
                />
              }
            />
            {lazyRoutes(appRoutes, layoutType)}
          </>
        );
      }
      default:
        return null;
    }
  }

  reduxStore.subscribe(() => {
    const { errors } = reduxStore.getState().systemReducer;
    if (errors.length) {
      showAlert(
        AryAlertTypes.ERROR,
        parseDescription(errors[0].templateId, errors[0].metadata)
      );
    }
  });

  useEffect(() => {
    if (window.location.pathname !== ApplicationPaths.LoginCallback) {
      bootstrap();
    }

    reduxStore.subscribe(() => {
      const { noVersionError, doBootstrap } =
        reduxStore.getState().systemReducer;
      if (noVersionError) {
        setNoVersionModalOpen(true);
        reduxStore.dispatch(setNoVersionError(false));
      }
      if (doBootstrap) {
        reduxStore.dispatch(setDoBootstrap(false));
        bootstrap();
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!getSystemSettingsLoading && getSystemSettingsQueryData) {
        if (hasSystemSettings(getSystemSettingsQueryData)) {
          i18n.changeLanguage(
            getSystemSettingsQueryData[arySystemSettings.systemLanguage] || 'fa'
          );
          setSystemSettings(getSystemSettingsQueryData);
          setContentStatus(contentTypes.application);
          window.paths.rptf =
            layoutType === 'mobile'
              ? window.paths.rptw
              : getSystemSettingsQueryData[arySystemSettings.showReportOnWeb]
              ? window.paths.rptw
              : window.paths.rptd;
          reduxStore.dispatch(updateUserCurrencies(true));
          const isVersionValid = await authService.isVersionValid();
          if (!isVersionValid) {
            if (
              window.location.pathname !==
              `/${ApplicationPaths.BranchConnection}`
            ) {
              reduxStore.dispatch(setNoVersionError(true));
            }
          }
        } else {
          const versionExpireTime = await authService.getVersionExpireTime();
          versionExpireTimeRef.current = versionExpireTime;
          if (new Date().getTime() < +versionExpireTime) {
            if (
              window.location.pathname ===
              `/${ApplicationPaths.BranchConnection}`
            ) {
              setContentStatus(contentTypes.application);
            } else {
              setContentStatus(contentTypes.settings);
            }
          } else if (
            window.location.pathname === `/${ApplicationPaths.BranchConnection}`
          ) {
            setContentStatus(contentTypes.application);
          } else {
            reduxStore.dispatch(setNoVersionError(true));
          }
        }
      }
    })();
  }, [getSystemSettingsQueryData]);

  useEffect(
    () => () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    },
    []
  );

  useEffect(() => {
    if (contentStatus === contentTypes.application) {
      document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.key === 'F5') {
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
        }
        if (event.ctrlKey) {
          switch (event.key) {
            case 'F1': {
              if (hasSubmitOutgoingDraftAccess) {
                navigate('../dashboard/draft/submission/outgoing');
              }
              break;
            }
            case 'F2':
              navigate('../dashboard/customer/list');
              break;
            case 'F3': {
              if (hasViewDraftDocumentsListAccess) {
                navigate('../dashboard/draft/draft-orders-list');
              }
              break;
            }
            case 'F5': {
              if (hasViewBranchesListAccess) {
                navigate('../dashboard/global-ledger/branch/list');
              }
              break;
            }
            case 'F6': {
              if (hasSubmitIncomingDraftAccess) {
                navigate('../dashboard/draft/submission/incoming');
              }
              break;
            }
            case 'F7': {
              if (hasSubmitCashTransactionAccess) {
                navigate('../dashboard/journal-entries/cash-transaction');
              }
              break;
            }
            case 'F8': {
              if (hasSubmitTransferBetweenAccountsAccess) {
                navigate(
                  '../dashboard/journal-entries/transfer-between-accounts'
                );
              }
              break;
            }
            case 'F9': {
              if (hasViewBankAccountsListAccess) {
                navigate('../dashboard/bankbook/list');
              }
              break;
            }
            case 'F10': {
              if (hasSubmitBankTransactionAccess) {
                navigate('../dashboard/journal-entries/bank-transaction');
              }
              break;
            }

            default:
              break;
          }
        }
      });
    }
  }, [contentStatus]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${t('Moneyx')}`}
        defaultTitle={`${t('Moneyx')} - ${t('Exchange Accounting Software')}`}
      />
      <SystemSettingsContext.Provider value={systemSettings}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={appTheme}>
            <StyledThemeProvider theme={appTheme}>
              <DirectionProvider rtl={langDirection === 'rtl'}>
                <LayoutTypeContext.Provider value={layoutType}>
                  <DescriptionTemplatesContext.Provider
                    value={descriptionTemplates}
                  >
                    <ShowCurrenciesContextProvider>
                      <Routes>
                        {getBaseRoute()}
                        <Route
                          exact
                          path={ApplicationPaths.LoginCallback}
                          element={<LoginCallbackHandler />}
                        />
                        <Route
                          exact
                          path={ApplicationPaths.BranchConnection}
                          element={
                            <BranchConnectionVerification
                              applicationContentType={contentTypes.application}
                              contentStatus={contentStatus}
                            />
                          }
                        />
                      </Routes>
                      {AryAlert}
                      {noVersionModalOpen && (
                        <Suspense fallback="">
                          <AryNoVersionModal
                            open={noVersionModalOpen}
                            handleClose={() => setNoVersionModalOpen(false)}
                            showConnectedBranchesButton
                            handleOnConnectedBranchesButtonClicked={() => {
                              if (!versionExpireTimeRef.current) {
                                setSystemSettings({
                                  mainCurrency: 'USD',
                                  fontSize: fontSizes.medium,
                                  isDigitsLatin: false,
                                  defaultPhonePrefix: '98',
                                  timeZone: 'Asia/Tehran',
                                  isGregorian: false,
                                  showCurrencyByTitle: true,
                                  profitAndLossAccuracy: 15,
                                  documents: [
                                    {
                                      documentTitle: '',
                                      isManual: false,
                                      issuingOrganization: ''
                                    }
                                  ],
                                  isDarkMode: false,
                                  file: undefined,
                                  exchangeName: '',
                                  phoneNumbers: [
                                    {
                                      phoneNumber: '',
                                      countryCode: '98',
                                      communicationWays: [1]
                                    }
                                  ],
                                  address: '',
                                  slogan: '',
                                  descriptions: [''],
                                  groupNames: ['عمومی', 'بانکی', 'هزینه'],
                                  userCurrencies: [
                                    {
                                      currencyCode: 'USD',
                                      decimalPlaces: 2,
                                      dollarRate: 1,
                                      isDivision: false
                                    }
                                  ],
                                  showReportOnWeb: true
                                });
                              }
                              setContentStatus(contentTypes.application);
                            }}
                          />
                        </Suspense>
                      )}
                    </ShowCurrenciesContextProvider>
                  </DescriptionTemplatesContext.Provider>
                </LayoutTypeContext.Provider>
              </DirectionProvider>
            </StyledThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </SystemSettingsContext.Provider>
    </HelmetProvider>
  );
};

export default App;
